<template>
  <div class="gjrcc">
    <div class="wrapper">
      <div class="gjrcc-list">
        <ul>
          <li>
            <div class="bgimg"></div>
            <div class="info">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon29.png" />
                <h3>高端才智</h3>
              </div>
              <div class="bd">
                以高层次人才为中心，采用线上网络平台建设与运营+线下高端才智引进及全链条服务体系双驱动的模式，横向衔接政府、企业、社会机构和专业人才，搭建沟通桥梁，建立起强大的“政企社才联盟”合作网，引进“国家级人才工程入选者”、“高精尖缺”等掌握国内外领先技术的人才及团队领军研发的高科技项目，全方位、多层次、高质量地助力科研成果转化。
              </div>
              <div class="ft">
                <a @click="toRouter('intelligence')"
                   target="_blank"
                   class="mbtn small orange ful">查看详情</a>
              </div>
            </div>
          </li>
          <li>
            <div class="bgimg"></div>
            <div class="info">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon30.png" />
                <h3>海创空间</h3>
              </div>
              <div class="bd">
                海创空间成立于2016年6月18日，占地面积约3000平方米，集创业项目孵化、高端人才引进、科技成果转化、资源共享交流为一体，打造全球科技成果转化中心和海内外人才创新创业基地。集聚资源，整合统效。海创空间集聚丰富的创新创业资源，依托完善的创新创业孵化服务体系，构建全链条创新创业生态系统，以科技服务为导向，聚焦产业，面向政府、双创基地和科技企业，提供全方位一站式服务。
              </div>
              <div class="ft">
                <a @click="toRouter('space')"
                   class="mbtn small orange ful">查看详情</a>
              </div>
            </div>
          </li>
          <li>
            <div class="bgimg"></div>
            <div class="info">
              <div class="hd">
                <img class="icon"
                     src="../../../assets/images/icon31.png" />
                <h3>创业培训</h3>
              </div>
              <div class="bd">
                创业培训赋能企业家，助力企业成长！以服务“大众创业、万众创新“为己任，尊重创业家精神，汇聚各类创业要素，深入开展创业研究、创业孵化、创业沙龙、创业培训、创业咨询、对标访学等一些活动，持续推进多层次、立体化的创新创业孵化课程及活动体系建设，为广大创业者搭建一个深度学习、交流互鉴、拓展人脉、共谋发展的广阔舞台。
              </div>
              <div class="ft">
                <a @click="toRouter('train')"
                   class="mbtn small orange ful">查看详情</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="gjrcc-btn">
        <a @click="toRouter('apply',{cn:'国际⼈才城',en:'INTERNATIONAL TALENT CITY',type:1})"
           class="mfullbtn"><img src="../../../assets/images/icon32.png" />入驻申请</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  mounted () { },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };

      this.newRouter('/' + routerName, query)
    },
  },
};
</script>
<style scoped lang="scss">
.gjrcc-list li:nth-child(1) .bgimg {
  background-image: url(../../../assets/images/gjrcbg01.jpg);
}
.gjrcc-list li:nth-of-type(2) .bgimg {
  background-image: url(../../../assets/images/gjrcbg03.jpg);
}
.gjrcc-list li:nth-of-type(3) .bgimg {
  background-image: url(../../../assets/images/gjrcbg03.jpg);
}
</style>
