<template>
  <div class="mbanner" >
    <div class="wrapper">
      <div class="mbanner-hd">
        <div class="cn"><h2>国际⼈才城</h2></div>
        <div class="en">International Talent City</div>
      </div>
      <div class="mbanner-bd mbanner-bd1">
        “海汇全球才智，创领国际之城”，石家庄国际人才城成立于2016年6月，是集高端人才引进、创业项目孵化、科技成果转化、<br />
        资源共享交流为一体，融通政府、企业、社会机构、专业人才的综合服务平台。<br />
        人才城以“政府引导、市场化运作”为总出发点，<br />
        采用线上网络平台建设与运营+线下高端才智引进及全链条服务体系双驱动的模式，<br />
        着力推进“十百千亿人才工程”，致力于打造国际人才集聚平台、<br />
        全球智力成果转化中心和海内外人才创新创业基地。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner05.jpg);
}
</style>
