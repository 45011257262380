<template>
  <div class="el_personnel">
    <banner />
    <introduce />
  </div>
</template>

<script>
import banner from "./banner.vue";
import introduce from "./introduce.vue";
export default {
  data() {
    return {
      msg: "el_personnel",
    };
  },
  components: {
    banner,
    introduce,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
